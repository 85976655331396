import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import axiosFile from 'axios';
import md5 from 'crypto-js/md5';
import { convertArrayToCSV } from 'convert-array-to-csv';
import { isAfter, isExists } from 'date-fns';
import { CSVReader } from 'react-papaparse';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Button,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { FileDownloadOutlined, CloseOutlined } from '@mui/icons-material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';
import ModuleHeader from '../../components/ModuleHeader';
import Table from '../../components/Table';
import colors from '../../assets/styles/colors';
import { changePage } from '../../actions';
import axios from '../../api';
import { changeBreadcrump, sortData, getComparator } from '../../actions';
import generateCsv from '../../utils/generateCsv';
import { env } from '../../config/environment';
import { useTranslation } from 'react-i18next';
import capitalize from '../../utils/capitalize';
import { encrypt } from '../../utils/crypt';

const buttonRef = React.createRef();

function Issue(props) {
  const { page, rowsPerPage, permission, changePage, user, organizationId, changeBreadcrump, styles } = props;
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [dataIssued, setDataIssued] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [invalid, setInvalid] = useState([]);
  const [dateDifferent, setDateDiffent] = useState([]);
  const [openBadges, setOpenBadges] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewEvery, setViewEvery] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(true);
  const [form, setForm] = useState({
    nombre: '',
    id_organizaciones:
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ||
      user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 14
        ? organizationId
        : '0',
    file: null,
  });
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [templateCertificate, setTemplateCertificate] = useState([]);
  const [fieldsCertificate, setFieldsCertificate] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(null);
  const headerArray = ['email_receptor', 'indicativo_pais', 'whatsapp_receptor'];
  const dataArray = ['', '', ''];
  const [codigoCertificado, setCodigoCertificado] = useState();
  const [columns, setColumns] = useState([
    {
      id: 'index',
      label: '#',
      minWidth: 50,
      align: 'center',
    },
  ]);
  const [encodingPlatform, setEncodingPlatform] = useState('');

  const [t] = useTranslation([
    'alerts',
    'tables',
    'buttons',
    'certificateBadgeIssued',
    'breadCrumps',
    'dialogs',
    'selects',
  ]);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    //await getBadgesIssued();
    await getOrganizaciones();
    await getTemplatesCertificates();
    changeBreadcrump(t('breadCrumps:breadCrumps.certificate-issue', { returnObjects: true }));
    const platform = window.navigator?.userAgentData?.platform?.toLowerCase();
    const platformAlt = window.navigator?.platform?.toLowerCase();
    if (platform) {
      platform.includes('windows') ? setEncodingPlatform('ISO-8859-3') : setEncodingPlatform('UTF-8');
    } else {
      platformAlt.includes('win') ? setEncodingPlatform('ISO-8859-3') : setEncodingPlatform('UTF-8');
    }
  };

  useEffect(() => {
    if (templateSelected?.id) {
      const template = templateCertificate?.filter((certificate) => certificate.id === templateSelected.id);
      setFieldsCertificate(template[0]?.certificadoCampos);
      setCodigoCertificado(template[0]?.codigo);
      templateSelected &&
        Swal.fire({
          icon: 'info',
          iconColor: colors.primary,
          text: t('alerts:alerts.download-template'),
          confirmButtonText: t('buttons:buttons.download'),
          buttonsStyling: false,
          customClass: { confirmButton: 'custom-button-confirm' },
        }).then(async (result) => {
          if (result.value) {
            downloadTemplate(template[0]?.certificadoCampos, template[0]?.codigo);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSelected]);

  const isSuperUser =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;
  const isDeparmentCreater = user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 6 ? true : false;

  const getTemplatesCertificates = async () => {
    if (isSuperUser) {
      const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesPublished/names`);
      setTemplateCertificate(data.certificateTemplates);
      if (data) setSelectLoading(false);
    } else {
      const { data } = await axios.post(`/certificateTemplate/getCertificateTemplatesPublished/names`, {
        id_organizaciones: organizationId,
      });
      if (isDeparmentCreater) {
        setTemplateCertificate(
          data.certificateTemplates.filter(
            (templateCertificate) =>
              templateCertificate.plantillasCertificadosHistorial[0].id_usuarios === user.data_user.id
          )
        );
      } else {
        setTemplateCertificate(data.certificateTemplates);
      }
      if (data) setSelectLoading(false);
    }
  };

  const getOrganizaciones = async () => {
    const { data } = await axios.post(`/organization/getOrganizations`);
    setOrganizations(data.organizations.rows);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const downloadTemplate = (fields, codeCertificate) => {
    const fieldsHeader = [];
    const fieldsData = [];
    fields
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      // eslint-disable-next-line array-callback-return
      .map((field) => {
        if (
          field.nombre !== 'qr' &&
          field.nombre !== 'logo' &&
          field.nombre !== 'certificateCode' &&
          field.nombre !== 'buttonVerify' &&
          field.nombre !== ''
        ) {
          if (fieldsHeader.includes(field.nombre)) {
            fieldsData.push(`Página ${field.page}`);
          } else {
            fieldsData.push('');
          }
          fieldsHeader.push(field.nombre);
        }
      });
    const header = headerArray.concat(fieldsHeader);
    const array = dataArray.concat(fieldsData);

    header.push('fecha_expiracion');
    array.push('20/09/2050');
    header.unshift('codigo_certificado');
    array.unshift(codeCertificate);
    const csvFromArrayOfArrays = convertArrayToCSV([array], {
      header,
      separator: ';',
    });
    generateCsv(csvFromArrayOfArrays, templateSelected);
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data, file) => {
    const warningsA = [];
    const issued = [];
    const preIssued = [];
    const invalidA = [];
    // eslint-disable-next-line
    const email = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const tel = /^([0-9]{5,13})+$/;
    const numbers = /^([0-9])*$/;
    const ind = /^([0-9]{1,5})+$/;

    const fields = ['codigo_certificado', 'email_receptor', 'indicativo_pais', 'whatsapp_receptor', 'fecha_expiracion'];
    const fieldTypes = [];
    // eslint-disable-next-line array-callback-return
    data[0].meta.fields.map((field) => {
      columns.push({
        id: `${field}`,
        label: `${field}`,
        align: 'center',
        orden: `data.${field}`,
      });
    });
    setColumns(columns);

    // eslint-disable-next-line array-callback-return
    fieldsCertificate.map((field) => {
      if (
        field.nombre !== 'qr' &&
        field.nombre !== 'logo' &&
        field.nombre !== 'certificateCode' &&
        field.nombre !== 'buttonVerify' &&
        field.nombre !== ''
      ) {
        fields.push(field.nombre);
        fieldTypes.push({ nombre: field.nombre, tipo: field.tipo_campo_vp });
      } else if (!fields.includes(field.nombre) && field.nombre !== '') {
        fields.push(field.nombre);
        fieldTypes.push({ nombre: field.nombre, tipo: field.tipo_campo_vp });
      }
    });

    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      if (fields.includes('qr') && !item.meta.fields.includes('qr')) {
        item.meta.fields.push('qr');
      }
      if (fields.includes('logo') && !item.meta.fields.includes('logo')) {
        item.meta.fields.push('logo');
      }
      if (fields.includes('certificateCode') && !item.meta.fields.includes('certificateCode')) {
        item.meta.fields.push('certificateCode');
      }
      if (fields.includes('buttonVerify') && !item.meta.fields.includes('buttonVerify')) {
        item.meta.fields.push('buttonVerify');
      }
      if (item.errors.length === 0 && item.meta.fields.length === fields.length) {
        if (JSON.stringify(item.meta.fields.sort()) === JSON.stringify(fields.sort())) {
          if (fields.includes('qr')) {
            item.data = {
              qr: 'qr-certika-600x600.png',
              ...item.data,
            };
          }
          if (fields.includes('logo')) {
            item.data = {
              logo: 'Certika-435x149.png',
              ...item.data,
            };
          }
          if (fields.includes('certificateCode')) {
            item.data = {
              certificateCode: 'certificateCode',
              ...item.data,
            };
          }
          if (fields.includes('buttonVerify')) {
            item.data = {
              buttonVerify: 'buttonVerify',
              ...item.data,
            };
          }
          issued.push(item.data);
        }
      }
    });

    const dates = issued.map((data) => data.fecha_expiracion);

    const repeatDates = dates.filter((item, index) => {
      return dates.indexOf(item) === index;
    });

    setDateDiffent(repeatDates);

    // eslint-disable-next-line array-callback-return
    issued.map((item, index) => {
      /* const repeat = [];
      issued.map(
        (data) =>
          data.codigo_certificado === item.codigo_certificado &&
          data.email_receptor === item.email_receptor &&
          repeat.push(true)
      ); */

      if (item.codigo_certificado.trim() !== '' && item.email_receptor.trim() !== '') {
        preIssued.push({
          index,
          ...item,
        });

        if (
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
        ) {
          if (!templateCertificate.find((e) => e.codigo === item.codigo_certificado.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.does-exit-template')} ${item.codigo_certificado}`,
            });
          }
        } else {
          if (
            !templateCertificate.find(
              (e) => e.codigo === item.codigo_certificado.trim() && e.id_organizaciones === organizationId
            )
          ) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.does-exit-template')} ${item.codigo_certificado}`,
            });
          }
        }

        /* if (repeat.length > 1) {
          warningsA.push({
            index,
            msg: `${t('alerts:alerts.template-assigned')} ${item.codigo_certificado} ${t('alerts:alerts.for-user')} ${
              item.email_receptor
            }`,
          });
        } */

        if (!email.test(item.email_receptor.trim())) {
          warningsA.push({
            index,
            msg: t('alerts:alerts.email-invalid'),
          });
        }

        if (item.indicativo_pais.trim() !== '') {
          if (!ind.test(item.indicativo_pais.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.indicative-invalid')}`,
            });
          }
        }

        if (item.whatsapp_receptor.trim() !== '') {
          if (!tel.test(item.whatsapp_receptor.trim())) {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.whatsapp-invalid')}`,
            });
          }
          if (item.indicativo_pais.trim() === '') {
            warningsA.push({
              index,
              msg: `${t('alerts:alerts.indicative-invalid')}`,
            });
          }
        }

        if (item.codigo_certificado.trim() !== codigoCertificado) {
          warningsA.push({
            index,
            msg: t('alerts:alerts.certificate-code-error'),
          });
        }

        // eslint-disable-next-line array-callback-return
        fieldTypes.map((field) => {
          if (item[field.nombre] !== '') {
            if (field.tipo === 41) {
              if (item[field.nombre].replace(/[^\\#+()$~%*?<>{}ïż½]/g, '').length !== 0) {
                warningsA.push({
                  index,
                  msg: `${t('alerts:alerts.field-invalid')} ${field.nombre}`,
                });
              }
            } else if (field.tipo === 42) {
              if (!numbers.test(item[field.nombre].trim())) {
                warningsA.push({
                  index,
                  msg: `${t('alerts:alerts.field-invalid')} ${field.nombre}`,
                });
              }
            } else if (field.tipo === 44) {
              if (!tel.test(item[field.nombre].trim())) {
                warningsA.push({
                  index,
                  msg: `${t('alerts:alerts.field-invalid')} ${field.nombre}`,
                });
              }
            } else if (field.tipo === 43) {
              if (!email.test(item[field.nombre].trim())) {
                warningsA.push({
                  index,
                  msg: `${t('alerts:alerts.field-invalid')} ${field.nombre}`,
                });
              }
            }
          } else {
            invalidA.push(index + 2);
          }
        });

        if (item.fecha_expiracion.trim() !== '') {
          const dateArray = item.fecha_expiracion.trim().split('/');
          const formattedDate = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
          if (
            !isExists(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt(dateArray[0])) ||
            !isAfter(formattedDate, new Date())
          ) {
            warningsA.push({
              index,
              msg: t('alerts:alerts.expiration-date-invalid'),
            });
          }
        }
      } else {
        invalidA.push(index + 2);
      }
    });

    const { data: contracts } = await axios.post(`/contract/validations/`, {
      id_organizaciones: form?.id_organizaciones,
      issued: preIssued,
    });

    const contract = contracts.contracts;
    if (contract.thereContrat) {
      const { id: idGroupUserOrg, id_grupos_usuarios } = user.data_user.gruposUsuariosOrganizaciones.find(
        (item) => item.id_organizaciones === organizationId
      );

      const isAnIssuer = id_grupos_usuarios === 4;
      if (isAnIssuer) {
        const { data: dataGroup } = await axios.get(`/userGroup/organizations/${idGroupUserOrg}`);
        const { limit_issued: limit, user_total_issueds: total_issued } = dataGroup.group;
        if (limit && preIssued.length > limit - total_issued) {
          return Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.exceeds-contract'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: { confirmButton: 'custom-button-confirm' },
          }).then(() => {
            handleRemoveFile();
          });
        }
      }
      if (contract.exceeds) {
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.exceeds-contract'),
          showConfirmButton: true,
          buttonsStyling: false,
          customClass: { confirmButton: 'custom-button-confirm' },
        }).then(() => {
          handleRemoveFile();
        });
      }
      if (contract.vencido) {
        return Swal.fire({
          icon: 'error',
          iconColor: colors.primary,
          text: t('alerts:alerts.expired-contract'),
          showConfirmButton: true,
          buttonsStyling: false,
          customClass: { confirmButton: 'custom-button-confirm' },
        }).then(() => {
          handleRemoveFile();
        });
      }
    } else {
      return Swal.fire({
        icon: 'error',
        iconColor: colors.primary,
        text: t('alerts:alerts.no-contract-error'),
        showConfirmButton: true,
        buttonsStyling: false,
        customClass: { confirmButton: 'custom-button-confirm' },
      }).then(() => {
        handleRemoveFile();
      });
    }

    changePage(0);
    setOpenBadges(true);
    setRows(preIssued);
    setDataIssued(issued);
    setWarnings(warningsA);
    setInvalid(invalidA);
    setForm({ ...form, file: file });
  };

  const handleRemoveFile = async (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
    setOpenBadges(false);
    setOpenDialog(false);
    setViewEvery(false);
    setRows([]);
    setWarnings([]);
    setForm({
      nombre: '',
      id_organizaciones:
        user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 1 ||
        user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 14
          ? organizationId
          : '',
      file: null,
    });
    setColumns([
      {
        id: 'index',
        label: '#',
        minWidth: 50,
        align: 'center',
      },
    ]);
  };

  const handlePrevSubmit = (e) => {
    e.preventDefault();
    setOpenBadges(false);
    setOpenDialog(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form?.mensaje && (form.mensaje.length < 60 || form.mensaje.length > 300)) {
      Swal.fire({
        title: 'Error',
        text: t('alerts:alerts.message-size'),
        icon: 'error',
        iconColor: colors.primary,
        timer: 4000,
        confirmButtonColor: colors.primary,
      });

      return false;
    }

    setOpenDialog(false);

    Swal.fire({
      text: `${t('alerts:alerts.button-emit-1')} ${dataIssued.length} ${t('alerts:alerts.button-emit-certificate-2')}`,
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: t('buttons:buttons.issue'),
      cancelButtonText: t('buttons:buttons.cancel'),
      denyButtonText: t('buttons:buttons.back'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
        denyButton: 'custom-button-back',
      },
    }).then(async (result) => {
      if (result.isDenied) {
        setOpenDialog(true);
      }
      if (result.isDismissed) {
        handleRemoveFile();
      }
      if (result.value) {
        const datapost = {
          nombre: form.nombre,
          mensaje: form.mensaje || `${t('alerts:alerts.message-issue')} "${templateSelected?.nombre}".`,
          codigoCertificado,
          certificados: rows,
          id_organizaciones: form.id_organizaciones,
          id_usuarios_emisores: user.data_user.id,
          archivo: form.file.name,
        };

        try {
          setLoading(true);

          /* Emisión */
          const { data } = await axios.post(`/certificatesIssued/`, datapost);

          /* Subir CSV de la emisión */
          const md5OrganizationRoute = md5(`certika-organization-${form.id_organizaciones}`).toString();
          const md5GroupRoute = md5(`certika-issued-group-${data.groupId}`).toString();
          const fileForm = new FormData();
          fileForm.append('file', form.file);
          fileForm.append('route', `${md5OrganizationRoute}/issue-files/${md5GroupRoute}/${md5GroupRoute}`);
          await axiosFile({
            method: 'post',
            url: `${env.apiURL}/file/upload-file`,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: fileForm,
          });

          setLoading(false);
          Swal.fire({
            icon: 'info',
            iconColor: colors.primary,
            text: t('alerts:alerts.certificates-issued'),
            showConfirmButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'custom-button-confirm',
            },
          }).then(async () => {
            handleRemoveFile();
            history.push(`/certificates/issued/${encrypt(data.groupId)}`);
          });
        } catch (error) {
          setLoading(false);
          handleRemoveFile();
          Swal.fire({
            icon: 'error',
            iconColor: colors.primary,
            text: t('alerts:alerts.certificate-issued-error'),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const loadFillDataOrganizaciones = () => {
    return organizations.map((data) => {
      return (
        <MenuItem key={data.id} value={data.id}>
          {capitalize(data.nombre)}
        </MenuItem>
      );
    });
  };

  const handleTemplateCertificate = (event, certificate) => {
    setTemplateSelected({ id: certificate?.id, nombre: certificate?.nombre });
  };

  const verTodos = () => {
    setViewEvery(true);
  };

  return (
    <>
      <ModuleHeader />

      <Card elevation={0}>
        <CardContent>
          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.complete-certificate')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.text-issued-1')}{' '}
            <b>{t('certificateBadgeIssued:issue.rows-certificate')} </b>
            {styles.organizationName === 'Certika'
              ? t('certificateBadgeIssued:issue.text-issued-certificate-2')
              : t('certificateBadgeIssued:issue.text-issued-certificate-2-min')}
          </Typography>
          <FormControl required variant="outlined" className="w-100">
            <Autocomplete
              options={templateCertificate.sort((a, b) => (a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1))}
              getOptionLabel={(option) => `${option.nombre} (${option.nombre_privado})`}
              onChange={handleTemplateCertificate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={selectLoading ? 'Cargando...' : t('selects:selects-autocompletes.certificate-select')}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
            {selectLoading && <LinearProgress />}
          </FormControl>
          <CSVReader
            ref={buttonRef}
            onFileLoad={handleOnFileLoad}
            noClick
            noDrag
            progressBarColor={colors.primary}
            config={{ header: true, encoding: encodingPlatform, skipEmptyLines: true }}
          >
            {({ file }) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Button
                  disabled={templateSelected ? false : true}
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={handleOpenDialog}
                  disableElevation
                >
                  {t('buttons:buttons.choose-csv')}
                </Button>
                <div
                  style={{
                    borderWidth: 1,
                    height: 45,
                    lineHeight: 2.5,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingLeft: 13,
                    paddingTop: 3,
                    width: '60%',
                  }}
                >
                  {file && file.name}
                </div>
              </div>
            )}
          </CSVReader>
          <Typography variant="body1" className={classes.text}>
            {t('certificateBadgeIssued:issue.uses-csv')}
          </Typography>
          <Link
            disabled={templateSelected ? false : true}
            component="button"
            to="#"
            onClick={() => downloadTemplate(fieldsCertificate, codigoCertificado)}
            className={templateSelected ? classes.link : classes.linkDisabled}
          >
            <Typography variant="body1" className={classes.text}>
              <FileDownloadOutlined />
              {t('certificateBadgeIssued:issue.dowload-template')}
            </Typography>
          </Link>
        </CardContent>
      </Card>

      <Dialog open={openBadges} fullWidth={true} maxWidth={'xl'} scroll={'body'}>
        <Grid container justify="flex-end">
          <IconButton className={classes.closeButton} onClick={handleRemoveFile}>
            <CloseOutlined />
          </IconButton>
        </Grid>
        <DialogTitle>
          <div className={classes.alert}>
            <Alert severity="info">
              {t('alerts:alerts.preloaded')} {rows.length} {t('alerts:alerts.of')} {dataIssued.length}{' '}
              {t('alerts:alerts.rows')}
            </Alert>
            {rows.length === 0 && <Alert severity="error">{t('alerts:alerts.no-certificate-to-issue')}</Alert>}
            {rows.length > 1000 && <Alert severity="error">{t('alerts:alerts.exceeded-limit')}</Alert>}
            {dateDifferent.length > 1 && <Alert severity="error">{t('alerts:alerts.expiration-date-equal')}</Alert>}
            {invalid.length > 0 && (
              <Alert severity="error">
                {t('alerts:alerts.rows-2')} <b>{invalid.toString()}</b> {t('alerts:alerts.no-requirements')}
              </Alert>
            )}
            {warnings.length > 5 && !viewEvery ? (
              <>
                {warnings.map(
                  (data, index) =>
                    index < 5 && (
                      <div key={`row${index}`}>
                        <Alert severity="error">
                          <b>
                            {t('alerts:alerts.row')} {data.index + 2}:
                          </b>{' '}
                          {data.msg}.
                        </Alert>
                      </div>
                    )
                )}
                <div>
                  <Alert severity="error">
                    <b>
                      5 {t('alerts:alerts.of')} {warnings.length} {t('alerts:alerts.errors')}.
                    </b>
                    <Button disableElevation onClick={verTodos} className={classes.añadirButton}>
                      {t('buttons:buttons.see-all')}
                    </Button>
                  </Alert>
                </div>
              </>
            ) : (
              <>
                {warnings.map((data, index) => (
                  <div key={`row${index}`}>
                    <Alert severity="error">
                      <b>
                        {t('alerts:alerts.row')} {data.index + 2}:
                      </b>
                      {data.msg}.
                    </Alert>
                  </div>
                ))}
              </>
            )}
          </div>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handlePrevSubmit}
            disabled={
              (rows.length !== dataIssued.length ||
                warnings.length > 0 ||
                rows.length === 0 ||
                rows.length > 1000 ||
                dateDifferent.length > 1 ||
                invalid.length > 0) &&
              true
            }
          >
            {t('buttons:buttons.issue')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handleRemoveFile}
          >
            {t('buttons:buttons.cancel')}
          </Button>
        </DialogTitle>

        <DialogContent dividers>
          {rows.length > 0 && (
            <Table columns={columns} rows={rows} setDirection={setOrdenDirection} setOrderBy={setValueToOrderBy}>
              <TableBody>
                {sortData(rows, getComparator(ordenDirection, valueToOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={`row${index}`}>
                      <TableCell align="center">
                        <b>{row.index + 2}</b>
                      </TableCell>
                      {columns
                        .filter((field) => field.label !== '#')
                        .map((field) => (
                          <TableCell align="center">{row[field.label]}</TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={openDialog} fullWidth={true} maxWidth={'sm'} style={{ zIndex: 100 }}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Typography gutterBottom>{t('dialogs:dialog.certificate-group-issue-name')}</Typography>
            <FormControl className="w-100">
              <TextField
                autoFocus
                required
                id="nombre"
                name="nombre"
                variant="outlined"
                onChange={handleInput}
                value={form.nombre}
              />
            </FormControl>
            <Typography gutterBottom>{t('dialogs:dialog.notification-message')}</Typography>
            <FormControl className="w-100">
              <TextField
                id="message"
                name="mensaje"
                variant="outlined"
                onChange={handleInput}
                value={form.mensaje || `${t('alerts:alerts.message-issue')} "${templateSelected?.nombre}".`}
                required
                multiline
              />
            </FormControl>
            {(user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
              user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14) && (
              <>
                <Typography gutterBottom style={{ marginTop: '1.5em' }}>
                  {t('dialogs:dialog.select-organization')} *
                </Typography>
                <FormControl required variant="outlined" className="w-100">
                  <Select
                    id="id_organizaciones"
                    name="id_organizaciones"
                    onChange={handleInput}
                    value={form.id_organizaciones}
                  >
                    <MenuItem>
                      <em>{t('selects:selects-autocompletes.select-option')}</em>
                    </MenuItem>
                    {loadFillDataOrganizaciones()}
                  </Select>
                </FormControl>
              </>
            )}
            <div className={`text-center ${classes.root}`} style={{ marginTop: '1.5em' }}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                type="submit"
              >
                {t('buttons:buttons.issue')}
              </Button>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={`my-2 ${classes.button}`}
                onClick={handleRemoveFile}
              >
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  button: {
    padding: '9px 40px',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    color: colors.primary,
    border: 'none',
    marginTop: '-1em',
    backgroundColor: '#fff',
    '&:hover': {
      color: colors.secondary,
    },
  },
  linkDisabled: {
    display: 'none',
    border: 'none',
    marginTop: '-1em',
  },
  closeButton: {
    margin: '.5em .5em 0 0',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
  añadirButton: {
    color: '#0056b3',
    textTransform: 'none',
    transitionProperty: 'none',
    '&:hover': {
      backgroundColor: 'none',
      textDecoration: 'underline',
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'emitir certificados')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(Issue);
